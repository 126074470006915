html, body, #root {
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #F9FBFD;
}

#metric-tags::-webkit-scrollbar       {background-color:#EDEDED;width:16px}
#metric-tags::-webkit-scrollbar-track {background-color:#EDEDED}
#metric-tags::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:5px solid #EDEDED}
#metric-progress-tags::-webkit-scrollbar       {background-color:#EDEDED;width:16px}
#metric-progress-tags::-webkit-scrollbar-track {background-color:#EDEDED}
#metric-progress-tags::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:5px solid #EDEDED}
#vehicle-tags::-webkit-scrollbar       {background-color:#EDEDED;width:16px}
#vehicle-tags::-webkit-scrollbar-track {background-color:#EDEDED}
#vehicle-tags::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:5px solid #EDEDED}
#vehicle-q-tags::-webkit-scrollbar       {background-color:#EDEDED;width:16px}
#vehicle-q-tags::-webkit-scrollbar-track {background-color:#EDEDED}
#vehicle-q-tags::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:5px solid #EDEDED}
