.paper-grey {
    background-color: #f6f6f6 !important;
    padding: 10px 10px !important;
    position: relative !important;
    box-shadow: none !important;
    border: solid #E6E6E7 2px !important;
    border-radius: 10px !important;
}

.tbl-border-right {
    border-right: 1px solid #e0e0e0;
}

.tbl-bordered {
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e0e0e0 !important;
}

.pull-right {
    display: flex;
    justify-content: flex-end;
}

.btn-grey {
    background-color: #cbcbcb !important;
    color: #121212 !important;
}

.btn-grey-border {
    border-color: #f6f6f6 !important;
}

.custom-textfield {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 2px solid #E6E6E7;
    box-sizing: content-box;
    height: 0.7em;
    margin: 0;
    padding: 16.5px 14px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.textfield-label {
    padding: 13px;
    background-color: #cbcbcb;
    min-height: 100px;
    border: 2px solid #E6E6E7;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#manage-roster td:nth-child(2) {
    background-color: #00000017;
}